


































import { formatDate, formatEvaluationTime } from '@/utils/helpers'
import { defineComponent } from '@vue/composition-api'
import type { Header } from '@/components/composables/useDataTable/types'
import { useDataTable } from '@/components/composables/useDataTable'
import PublishedTenderService from '@/services/publishedTender'
import Vue from 'vue'
import BidButton from '@/components/buttons/BidButton.vue'

export default defineComponent({
  setup() {
    const { items, totalRecords, loading, pagination, search, query, pages, fetchData } = useDataTable(
      PublishedTenderService,
      Vue.observable({ type: 'sell' }),
    )

    return {
      items,
      totalRecords,
      loading,
      pagination,
      search,
      query,
      pages,
      fetchData,
      formatDate,
      formatEvaluationTime,
      PublishedTenderService,
    }
  },
  name: 'PublishedSellingTendersList',
  components: { BidButton },
  computed: {
    headers(): Array<Header> {
      return [
        { value: 'name', text: this.$t('name'), sortable: true },
        { value: 'publishDate', text: this.$t('publishDate'), sortable: true },
        { value: 'endDate', text: this.$t('closureDate'), sortable: true },
        { value: 'offerValidityMinutes', text: this.$t('evaluationDuration'), sortable: true },
        { value: 'actions', text: this.$t('actions'), sortable: false },
      ]
    },
  },
})
